.signup-div {
    font-family: Arial, Helvetica, sans-serif;
    border-top: 4px solid rgb(87, 207, 87);
    border-bottom: 4px solid rgb(87, 207, 87);
    background-color: rgb(112, 112, 112);
    color: white;
    text-align: center;
    padding: 0px 20px;
    width: 330px;
}

.modalText {
    color: white;
}

.signup-form {
    display: flex;
    justify-content: center;
    flex-flow: column;
}

.signup-errors {
    margin: 8px 0px 6px;
}

.signup-text {
    margin: 6px 0px;
}

.confirm-signup {
    margin: 6px 0px 10px;
    margin-left: auto;
    margin-right: auto;
    width: 40%;

    background-color: rgb(112, 112, 112);

    border: none;
    border-top: 1px solid rgb(153, 153, 153);
    border-bottom: 1px solid rgb(153, 153, 153);

    font-size: 18px;
    color: white;

    padding: 6px 0px;
}

.confirm-signup:hover {
    background-color: rgb(121, 121, 121);
    transition: .5s;
}
