body {
  margin: 0px;
}

.profile-dropdown {
  display: flex;
  flex-flow: column;
  list-style-type: none;
  position: fixed;
  right: 0px;
  padding: 4px;
  background-color: rgb(112, 112, 112);
  border: 2px solid rgb(87, 207, 87);
  color: white;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  border-radius: 8px;
  text-align: center;
  min-height: 75px;
  min-width: 116px;
  margin-right: 24px;
  margin-top: 2px;
}

.hidden {
  display: none;
}


.allNav{

  color: white;
  padding: 0px 10px;
  margin: 0px 10px;
  border: 2px solid white;

}
.allNav:hover{
  background-color: white;
  color: rgb(112, 112, 112);
}

.nav-bar {
  list-style-type: none;
  display: flex;
  justify-content: space-between;
  background-color: rgb(112, 112, 112);
  margin: 0px;
  padding: 14px 0px;
  padding-bottom: 12px;
  border-bottom: 6px solid rgb(87, 207, 87);
  border-image: linear-gradient(45deg, rgb(87, 207, 87), rgb(207, 115, 87)) 1;
  height: 76px;
}

.website-title {
  font-size: 36px;
  text-decoration: none;
  font-family: Arial, Helvetica, sans-serif;
  color: white;
  font-weight: bolder;
  margin-left: 26px;
}

.profile-button {
  width: 80px;
  height: 36px;
  margin-right: 32px;
  margin-top: 8px;
  background-color: rgb(112, 112, 112);

  border: none;
  border-left: 1px solid rgb(153, 153, 153);
  border-right: 1px solid rgb(153, 153, 153);

  font-size: 24px;
  color: white;
}

.profile-button:hover {
  background-color: rgb(121, 121, 121);
  transition: .5s;
  cursor: pointer;
}

.logout-btn, .login-btn, .signup-btn {
  background-color: rgb(112, 112, 112);

  border: none;
  /* border-top: 1px solid rgb(153, 153, 153);
  border-bottom: 1px solid rgb(153, 153, 153); */
  padding-top: 10px;
  padding-bottom: 10px;
  width: max-content;

  color: white;
  font-size: 16px;
  bottom: 0px;

  cursor: pointer;
}

.logout-btn:hover, .signup-btn:hover, .login-btn:hover, .songs-link:hover, .albums-link:hover, .profile-link:hover {
  text-decoration: underline;
}

.username-li, .email-li {
  margin: 6px 0px;
  font-weight: bold;
}

.albums-li, .songs-li, .profile-li {
  padding: 8px 0px;
}

.profile-li {
  border-top: 1px solid rgb(153, 153, 153);
  padding-bottom: 6px;
}

.albums-li {
  padding: 6px 0px;
}

.songs-li {
  border-bottom: 1px solid rgb(153, 153, 153);
  padding-top: 6px;
}

.songs-link, .albums-link, .profile-link {
  color: white;
}

.login-btn, .signup-btn {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  border: none;
}

.signup-btn {
  border-top: 1px solid rgb(153, 153, 153);
}
