.song-div {
    margin: 24px;
    padding: 16px;
    background-color: rgb(131, 131, 131);

    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    border-bottom: 4px solid rgb(87, 207, 87);

    color: white;
    display: flex;

    min-width: 800px;
}

.song-picture-div {
    width: 150px;
    height: 150px;
    border: 2px solid rgb(87, 207, 87);
    margin-left: 8px;
    margin-right: 16px;
}

.song-picture {
    width: 100%;
    height: 100%;
}

#songPage {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    /* border: red solid 3px; */
}

#searchBar {
    min-width: 30%;
    margin: 20px;
}

.artistName{
    text-decoration: none;
    color: white;
}
