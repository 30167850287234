.user-album-page-div {
    margin: 24px;
}

.album-div {
    margin-left: 0px;
    margin-right: 0px;
    padding: 16px;
    background-color: rgb(131, 131, 131);

    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    border-bottom: 4px solid rgb(207, 115, 87);

    color: white;
    display: flex;
}

.album-pic-div {
    width: 150px;
    height: 150px;
    border: 2px solid rgb(207, 115, 87);
    margin-left: 8px;
    margin-right: 16px;
}

.album-pic {
    width: 100%;
    height: 100%;
}