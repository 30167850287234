/* TODO Add site wide styles */
* {
    box-sizing: border-box;
  }

  body {
    font-family: sans-serif;
    background: #f2f2f2;
  }

  .audio-player {
    /* background: #c1b6bc; */
    background: linear-gradient(45deg, rgb(121, 121, 121) 20%, rgb(207, 115, 87));
  }
  h1{
    color: rgb(121, 121, 121);
    text-shadow: 1px 1px 2px black;
  }

  .inner {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }

  /* audio info */
  .audio-info {
    display: flex;
    gap: 20px;
  }

  .text {
    color: #f50;
  }

  .title {
    color: #fff;
    font-weight: 100;
    font-size: 32px;
    margin-bottom: 0;
    padding: 8px;
    line-height: 37px;
    background-color: rgba(0,0,0,.8);
    border-radius: 8px;
  }

  /* audio image */
  .icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .audio-image {
    width: 300px;
    height: 300px;
    background: #1e1e1e;
  }

  .audio-icon {
    font-size: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    width: 80px;
    border-radius: 50%;
    background: #989898;
  }

  .audio-image img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    vertical-align: middle;
  }

  /* controls */
  .controls-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
  }

  .controls button, .volume button {
    border: none;
    background-color: transparent;
    margin-right: 8px;
    cursor: pointer;
  }

  button svg {
    font-size: 20px;
  }

  .volume {
    display: flex;
    align-items: center;
  }

  .volume button {
    margin: 0;
  }

  .progress {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
  }

  /* time */
  .time {
    color: #333;
    font-size: 13px;
    line-height: 46px;
  }

  .time.current {
    color: #f50;
  }

  /* Top bar */
  .top__bar {
    font-size: 0.875rem;
    line-height: 1.25rem;
    text-align: center;
    padding-top: .5rem;
    padding-bottom: .5rem;
    background-color: #fafafa;
    border-bottom: 1px solid #f0f0f2;
  }

  .top__bar a {
    color: inherit;
  }

  .top__bar p {
    margin: 0;
  }
