.user-profile-page-div {
    margin: 24px;
}

.user-profile-header {
    background-color: rgb(121, 121, 121);
    color: white;

    padding: 16px;

    border-radius: 16px;
}

.user-main-details {
    display: flex;
    background: linear-gradient(25deg, rgb(121, 121, 121) 45%, rgba(152, 107, 173, 0.8));
    padding: 16px;
    border-top: 4px solid rgb(169, 87, 207);
    border-bottom: 4px solid rgb(169, 87, 207);
}

.user-text-details {
    font-size: 24px;
    color: white;
    margin-left: 24px;
    font-weight: bold;
}

.user-image-div {
    border: 4px solid rgb(169, 87, 207);
    width: 350px;
    height: 350px;
}

.user-image {
    width: 100%;
    height: 100%;
}

.user-text-username, .user-text-full-name {
    background-color: black;
    padding: 12px;
    border-radius: 8px;
}

.user-profile-ui {
    display: flex;

    background-color: rgb(121, 121, 121);
    padding: 24px;

    border-bottom: 4px solid rgb(169, 87, 207);
}

.user-profile-ui-link {
    color: white;
    padding: 12px;
    border-left: 1px solid rgb(153, 153, 153);
    border-right: 1px solid rgb(153, 153, 153);
    margin-left: -1px;
}

.user-profile-ui-link:hover {
    background-color: rgba(140, 187, 209, 0.7);
    transition: .5s;
}

.user-album-header, .user-song-header {
    background-color: rgb(121, 121, 121);
    color: white;

    padding: 16px;
    margin-bottom: 0px;

    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}

.user-albums-div {
    background-color: rgb(121, 121, 121);
    color: white;
    text-decoration: none;
    padding: 16px;
    border-top: 4px solid rgb(207, 115, 87);
    border-bottom: 4px solid rgb(207, 115, 87);
}

.user-album-div, .user-song-div {
    display: flex;
    margin: -1px 0px;
    border: 1px solid lightgray;
    border-left: none;
    border-right: none;
    padding: 12px 0px;
}

.user-album-div:hover, .user-song-div:hover {
    background-color: rgb(131, 131, 131);
    transition: .5s;
}

.user-album-pic-div {
    width: 75px;
    height: 75px;
    border: 2px solid rgb(207, 115, 87);
    margin-right: 12px;
}

.user-album-pic, .user-song-pic {
    width: 100%;
    height: 100%;
}

.user-album-name, .user-song-name {
    margin-top: 26px;
    margin-left: 4px;

    color: white;
    text-decoration: none;
    font-weight: bold;
}

.user-songs-div {
    background-color: rgb(121, 121, 121);
    color: white;
    text-decoration: none;
    padding: 16px;
    border-top: 4px solid rgb(87, 207, 87);
    border-bottom: 4px solid rgb(87, 207, 87);
}

.user-song-pic-div {
    width: 75px;
    height: 75px;
    border: 2px solid rgb(87, 207, 87);
    margin-right: 12px;
}