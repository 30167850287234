.album-page-div {
    margin: 24px;
}

.album-name-header {
    background-color: rgb(121, 121, 121);
    color: white;

    padding: 16px;
    margin-bottom: 0px;

    border-top-left-radius: 20px;
    border-top-right-radius: 20px;

    min-width: 300px;
}

.album-details-1 {
    background-color: rgb(121, 121, 121);
    color: white;
    display: flex;
    padding: 16px;

    /* border-radius: 16px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px; */

    border-top: 4px solid rgb(207, 115, 87);
    border-bottom: 4px solid rgb(207, 115, 87);
}

.album-page-pic-div {
    width: 300px;
    height: 300px;
    border: 4px solid rgb(207, 115, 87);
    margin-right: 24px;
}

.album-page-pic {
    width: 100%;
    height: 100%;
}

.album-song-header {
    background-color: rgb(121, 121, 121);
    color: white;

    padding: 16px;
    margin-bottom: 0px;

    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}

.album-songs-div {
    background-color: rgb(121, 121, 121);
    color: white;
    text-decoration: none;
    padding: 16px;
    border-top: 4px solid rgb(87, 207, 87);
    border-bottom: 4px solid rgb(87, 207, 87);
}

.album-song-div {
    display: flex;
    margin: -1px 0px;
    border: 1px solid lightgray;
    border-left: none;
    border-right: none;
    padding: 12px 0px;
}

.album-song-div:hover {
    background-color: rgb(131, 131, 131);
    transition: .5s;
}

.album-song-pic-div {
    width: 75px;
    height: 75px;
    border: 2px solid rgb(87, 207, 87);
    margin-right: 12px;
}

.album-song-pic {
    width: 100%;
    height: 100%;
}

.album-song-name {
    margin-top: 26px;
    margin-left: 4px;

    color: white;
    text-decoration: none;
    font-weight: bold;
}

a:-webkit-any-link {
    text-decoration: none;
}

.album-user-settings-header {
    background-color: rgb(121, 121, 121);
    color: white;

    padding: 16px;
    margin-bottom: 0px;

    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}

.album-user-settings-ui {
    background-color: rgb(121, 121, 121);
    color: white;
    text-decoration: none;
    padding: 16px;
    border-top: 4px solid rgb(140, 187, 209);
    border-bottom: 4px solid rgb(140, 187, 209);

    display: flex;
}

.album-user-settings-ui button:hover {
    cursor: pointer;
}

.album-user-settings-del, .album-user-settings-edit {
    background-color: rgb(121, 121, 121);

    border: none;
    border-right: 1px solid rgb(153, 153, 153);
    border-left: 1px solid rgb(153, 153, 153);

    color: white;
    font-size: 16px;

    /* margin-bottom: 8px;
    margin-top: 8px; */

    padding: 6px 18px;
}

.album-user-settings-edit {
    margin-left: -1px;
}

.album-user-settings-del:hover {
    background-color: rgba(180, 105, 105, 0.8);
    transition: .5s;
}

.album-user-settings-edit:hover {
    background-color: rgba(140, 187, 209, 0.7);
    transition: .5s;
}
