.loadingBar {
    min-width: 100%;
    min-height: 100%;
}

.loadingArea {
    height: 5px;
    width: 500px;
    display: flex;
    justify-content: center;
    margin: 10px;
    /* border: 2px green dotted; */
}

.newSongForm {
    /* border: red 2px dotted; */
    display: flex;
    flex-direction: column;
    align-items: center;
}

.newSongFormDetails {
    /* border: blue 2px dotted;
    display: flex;
    justify-content: center; */
    color: white;
    font-weight: bold;
}

.form-header {
    background-color: rgb(121, 121, 121);
    color: white;
    margin-bottom: 0px;
    padding: 12px;

    border-top-left-radius: 20px;
    border-top-right-radius: 20px;

    border-bottom: 4px solid rgb(207, 179, 87);
}

.ui-portion {
    display: flex;
    margin: 24px 0px;
}

.name-input {
    background-color: rgb(121, 121, 121);
    padding: 12px;

    /* border-top: 4px solid rgb(87, 207, 87); */
    border-bottom: 4px solid rgb(87, 207, 87);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}

.two {
    background-color: rgb(121, 121, 121);
    padding: 12px;

    border-top: 4px solid rgb(169, 87, 207);
    border-bottom: 4px solid rgb(169, 87, 207);
}

.three {
    background-color: rgb(121, 121, 121);
    padding: 12px;

    border-top: 4px solid rgb(207, 115, 87);
    border-bottom: 4px solid rgb(207, 115, 87);
}

.album-input {
    margin-right: 159px;
}

.four {
    background-color: rgb(121, 121, 121);
    padding: 12px;

    border-top: 4px solid rgb(140, 187, 209);
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;

    text-align: center;
}

.confirm-submit {
    background-color: rgb(121, 121, 121);
  
    border: none;
    border-right: 1px solid rgb(153, 153, 153);
    border-left: 1px solid rgb(153, 153, 153);

    color: white;
    font-size: 16px;

    /* margin-bottom: 8px;
    margin-top: 8px; 
    margin-left: -1px; */

    padding: 6px 18px;
}

.confirm-submit:hover {
    background-color: rgba(140, 187, 209, 0.7);
    transition: .5s;
}